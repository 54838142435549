<template>
  <div>
    <v-app-bar :elevation="1">
      <v-app-bar-nav-icon icon="mdi:mdi-chevron-left" @click="handleGoBack()" />
      <v-app-bar-title>
        <template v-if="sale.operation == 'sale'">
          <span class="text-capitalize">Venda</span>
        </template>
        <template v-else>
          <span class="text-capitalize text-error">Devolução</span>
        </template>
        <!-- <DrawerStatusChip class="ml-2" :status="isOpen" /> -->
      </v-app-bar-title>
      <template v-slot:append>
        <template v-if="!$vuetify.display.mobile">
          <div style="width:250px">
            <SalesmanSearch v-model="sale.salesman" />
          </div>
        </template>
      </template>
    </v-app-bar>
    <v-main>
      <!-- <FreeTrialAlert /> -->
      <slot />
    </v-main>
    <template v-if="$vuetify.display.mobile">
      <div id="appBottomBar">

      </div>
    </template>
  </div>
</template>

<script setup>

const router = useRouter();

const route = useRoute();

const saleFormStore = useSaleFormStore();

const { sale } = storeToRefs(saleFormStore)

const drawerStore = useDrawerStore();

const { isOpen } = storeToRefs(drawerStore);

const handleGoBack = () => {
  if (route.meta.backTo) {
    router.push(route.meta.backTo)
  }
  else {
    router.back()
  }
}

</script>

